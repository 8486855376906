.hero {
    background-image: url('https://example.com/your-background-image.jpg'); /* Add your blue nature-themed coding image */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 50px; /* Increased padding for taller section */
    text-align: center;
    border-bottom: 5px solid #0056b3;
    position: relative;
    z-index: 1;
}
.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 123, 255, 0.5); /* Translucent blue overlay */
    z-index: -1;
}
.hero h1 {
    font-size: 28px;
}
.subhero {
    font-size: 20px;
}
.container-showcase {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3rem;
    padding: 4rem;
    border-radius: 18px;
}

.showcase-heading-container {
  text-align: center;
  padding: 0 14rem;
  margin-bottom: 2rem;
}

.showcase-heading {
  font-size: 30px;
  font-weight: 600;
  color: #00348d;
}

.showcase-content {
  font-size: 13px;
  font-weight: 500;
  color: #666666e3;
  margin-bottom: 1rem;
}

.feature {
    width: 20rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin: 10px;
    padding: 20px;
    /* flex: 1 1 calc(30% - 40px); */
    text-align: left; /* Align text to the left */
    transition: transform 0.3s, box-shadow 0.3s;
    min-height: 220px; /* Ensuring minimum height for all boxes */
}
.feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0,0,0,0.15);
}
.feature h2 {
    color: black; /* Black color for headings */
    font-size: 22px;
    margin-bottom: 10px; /* Space below headings */
    font-weight: 600;
}
.feature p {
    color: #555;
    font-size: 14px; /* Reduced font size for better readability */
    line-height: 1.5; /* Added line height for better spacing */
}
.btn {
    background-color: #007BFF; /* Blue color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 15px; /* Rounded corners */
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.btn-view-demo {
    width: fit-content;
    border-radius: 10px;
}

.data-query {
    background-color: #fceef1; /* Lightest Red */
}
.companion-ai {
    background-color: #f5f1fc; /* Light Lavender */
}
.resume-analyzer {
    background-color: #e8f8f3; /* Pale Green */
}
.voice-bot {
    background-color: #fff1e8; /* Lightest Orange */
}
.finbot {
    background-color: #ecf3ff; /* Powder Blue */
}

.poc-icon {
    width: 2rem;
}

@media only screen and (max-width: 984px) {
    /* Styles for devices with a maximum width of 768 pixels */
    .showcase-heading-container {
        padding: 0 4rem;
      }
}

@media only screen and (max-width: 768px) {
    /* Styles for devices with a maximum width of 768 pixels */
    .showcase-heading-container {
        padding: 0 2rem;
      }
}

@media (max-width: 768px) {
    .feature {
        flex: 1 1 calc(45% - 40px);
    }
}
@media (max-width: 480px) {
    .feature {
        flex: 1 1 calc(100% - 40px);
        width: auto;
    }
    .container-showcase {
        padding: 2rem 1rem;
        
    }
}
