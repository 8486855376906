/* Specific to FeatureSection Component */
.hero-feature-section {
  background-color: #f8f9fa; /* Light background for the section */
  padding: 40px 0;
}

.hero-section-title {
  font-size: 3rem;
  font-weight: 600;
  color: #1a1a1a; /* Dark text */
  padding: 0 8rem;
}

.hero-section-subtitle {
  color: #6c757d; /* Grey text */
  font-size: 1rem;
  margin-bottom: 40px;
}

/* Feature Icon Box */
.hero-feature-icon {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 12px; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
  width: 6rem;
  cursor: pointer;
}

.hero-feature-icon-box img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

/* Icon labels */
.hero-feature-icon-box p {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

/* Hover effect on feature boxes */
.hero-feature-icon:hover {
  transform: translateY(-10px);
}

/* Icon Backgrounds (specific color for each feature) */
.hero-data-query {
  background-color: #ff5e5e; /* Red */
  color: #ff5e5e;
}

.hero-resume-analyse {
  background-color: #2bb67b; /* Green */
  color: #2bb67b;
}

.hero-companion-ai {
  background-color: #7b5cc9; /* Purple */
  color: #7b5cc9;
}

.hero-finbot {
  background-color: #4b8ff7; /* Blue */
  color: #4b8ff7;
}

.resume-analyser-icon-text {
  color: #12b77e;
}

.data-query-icon-text {
  color: #df5470;
}

.voice-bot-icon-text {
  color: #fe7211;
}

.finbot-icon-text {
  color: #3c81f6;
}

.companion-ai-icon-text {
  color: #966ddf;
}

.data-query-icon-text,
.resume-analyser-icon-text,
.voice-bot-icon-text,
.finbot-icon-text,
.companion-ai-icon-text {
  font-size: 11px;
  margin-left: -17px;
  margin-top: -20px;
  font-weight: 600;
}

@media screen and (min-width: 769px) {
  .finbot-icon-hero-box {
    position: absolute;
    bottom: -4rem;
  }
}

@media only screen and (max-width: 768px) {
  /* Styles for devices with a maximum width of 768 pixels */
  .width-auto {
    width: auto !important;
  }

  .center-elements {
    justify-content: center !important;
    margin-left: 3px;
  }
  .hero-section-title {
    font-size: 28px;
    padding: 0 2rem;
  }
}
