body {
  margin: 0;
  margin-bottom: 3rem;
  font-family: "Poppins", sans-serif !important;
  /* font-family: "Times New Roman", sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-1: #4339f2;
  --color-2: #ff3333;
  --color-3: #34b53a;
  --color-4: #c6ebc8;
  --color-5: #ebf8eb;
  --color-6: #ffb200;
  --color-7: #fff7e6;
}

/*** LOADER ***/
.loader {
  position: fixed;
  background: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 999999;
}

.data-loader {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.pe-cursor {
  cursor: pointer;
}

/*** POSITIONING ***/
.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-left {
  position: sticky;
  left: 0;
}

.sticky-right {
  position: sticky;
  right: 0;
}

/** CUSTOM SHADOW **/
.custom-shadow {
  background: white;
  box-shadow: 0px 0px 8px 2px #dbdbdb47;;
}