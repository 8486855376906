/* Background for square boxes */
.background-boxes {
    position: absolute; /* Stays fixed in the background */
    width: 100vw;
    z-index: 0; /* Puts it behind all content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 14rem;
    opacity: 0.2; /* Light opacity for subtle background */
  }
  
  /* Each row contains multiple boxes */
  .background-boxes .row {
    display: flex;
  }
  
  /* Each box is a square */
  .background-boxes .box {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Different shades of grey for variety */
  .background-boxes .box:nth-child(odd) {
    background-color: #fafafa; /* Lighter */
  }
  
  .background-boxes .box:nth-child(2n) {
    background-color: #e0e0e0; /* Slightly darker */
  }
  
  .background-boxes .box:nth-child(3n) {
    background-color: #f5f5f5; /* Lighter */
  }
  
  .background-boxes .box:nth-child(4n) {
    background-color: #dcdcdc; /* Darker */
  }
  