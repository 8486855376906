.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  background-color: rgb(222, 222, 222);
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(111, 111, 111, 0.77);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(160, 160, 160, 0.77);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-blue {
  background-color:#4339f2;
}

.shadow-lg {
  background-color: white;
}

/*** NAVBAR ***/
.navbar {
  background-color: #f8f9fa;
  padding: 0 64px !important;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 99;
}

.home-icon {
  width: 160px;
  transition: transform 0.2s;
}

.icon {
  width: 20px;
  height: 20px;
  margin: 20px 0;
  transition: transform 0.2s;
}

.icon:hover {
  transform: scale(1.2);
}

/*** SHADOW OF CARD ***/
.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
  width: --webkit-fill-available;
}

/*** ALIGN ITEMS VERTICALLY AND HORIZONTALLY CENTER ***/
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/*** STICKY COLUMNS ***/
.enquiry-status {
  position: sticky;
  left: 0;
  z-index: 1;
  top: 0;
}

/** MEDIA QUERIES **/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1496px !important;
  }
}

@media (min-width: 1920px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1800px !important;
  }
}
